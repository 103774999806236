import {
  KEY_BEZEL_COLOURS,
  KEY_BAFFLE_COLOURS,
  KEY_BEAM_ANGLES,
  KEY_COLOUR_TEMPERATURE,
  KEY_ENGINE_TYPES,
  KEY_OPTIONS,
  KEY_DRIVERS,
} from './constants';

let STATEMENTS = [
  // https://bravemarketing.atlassian.net/wiki/spaces/OR/pages/1530593281/Spec+Code+Functionality#%E2%80%9CColour-Temperature%E2%80%9D-Field-Dependencies
  {
    check: (product, codeGroup) => {
      if (
        codeGroup[KEY_ENGINE_TYPES].value === 'or' ||
        codeGroup[KEY_ENGINE_TYPES].value === 'nl' ||
        codeGroup[KEY_ENGINE_TYPES].value === 'ho' ||
        codeGroup[KEY_ENGINE_TYPES].value === 'lo'
      )
        return true;
      return false;
    },
    execute: (data) => {
      const colour_temperature = data[KEY_COLOUR_TEMPERATURE][0].map(
        (colour) => {
          colour.disabled = colour.value === 'wd' ? true : colour.disabled;
          return colour;
        }
      );
      return {
        ...data,
        colour_temperature: [colour_temperature],
      };
    },
  },
  // https://bravemarketing.atlassian.net/browse/OR-1417
  {
    check: (product, codeGroup, optionsValues) => {
      if (
        (product === 'toto-zero' ||
          product === 'toto-micro' ||
          product === 'toto-mini' ||
          product === 'toto-plus' ||
          product === 'toto' ||
          product === 'alta-zero' ||
          product === 'alta-micro' ||
          product === 'alta-mini' ||
          product === 'alta' ||
          product === 'alta-plus') &&
        optionsValues.indexOf('54') > -1
      )
        return true;
      return false;
    },
    execute: (data) => {
      if (data !== undefined) {
        if (data[KEY_OPTIONS] === undefined) return;
        const options = data[KEY_OPTIONS][0].map((option) => {
          if (option.value === '67') option.disabled = true;
          return option;
        });

        return {
          ...data,
          options: [options],
        };
      }
    },
  },
  {
    check: (product, codeGroup) => {
      if (
        codeGroup[KEY_ENGINE_TYPES].value === 'or' &&
        (product === 'toto-zero' ||
          product === 'toto-micro' ||
          product === 'toto-mini' ||
          product === 'toto-plus' ||
          product === 'toto' ||
          product === 'mite' ||
          product === 'alta-zero' ||
          product === 'alta-micro' ||
          product === 'alta-mini' ||
          product === 'alta' ||
          product === 'alta-plus' ||
          product === 'elia' ||
          product === 'vara' ||
          product === 'frame' ||
          product === 'ori' ||
          product === 'arto' ||
          product === 'vio-square' ||
          product === 'vio-tall')
      )
        return true;
      return false;
    },
    execute: (data) => {
      const colour_temperature = data[KEY_COLOUR_TEMPERATURE][0].map(
        (colour) => {
          colour.disabled = colour.value === '40' ? true : colour.disabled;
          return colour;
        }
      );
      return {
        ...data,
        colour_temperature: [colour_temperature],
      };
    },
  },
  // https://bravemarketing.atlassian.net/wiki/spaces/OR/pages/1530593281/Spec+Code+Functionality#%E2%80%9CColour-Temperature%E2%80%9D-Field-Dependencies
  {
    check: (product, codeGroup) => {
      if (codeGroup[KEY_ENGINE_TYPES].value === 'wd') return true;
      return false;
    },
    execute: (data) => {
      const colour_temperature = data[KEY_COLOUR_TEMPERATURE][0].map(
        (colour) => {
          colour.disabled = colour.value === 'wd' ? false : colour.disabled;
          return colour;
        }
      );
      const drivers = data[KEY_DRIVERS][0].map((driver) => {
        driver.disabled = driver.value === 'n' ? true : false;
        return driver;
      });
      return {
        ...data,
        colour_temperature: [colour_temperature],
        drivers: [drivers],
      };
    },
  },
  // https://bravemarketing.atlassian.net/wiki/spaces/OR/pages/1530593281/Spec+Code+Functionality#%E2%80%9CColour-Temperature%E2%80%9D-Field-Dependencies
  {
    check: (product, codeGroup) => {
      if (codeGroup[KEY_ENGINE_TYPES].value === '100') return true;
      return false;
    },
    execute: (data) => {
      const colour_temperature = data[KEY_COLOUR_TEMPERATURE][0].map(
        (colour) => {
          colour.disabled =
            colour.value === '27' || colour.value === '30' ? false : true;
          return colour;
        }
      );

      return {
        ...data,
        colour_temperature: [colour_temperature],
      };
    },
  } /*
  {
    check: (product, codeGroup, optionsValues) => {
      if (codeGroup[KEY_OPTIONS].length && (optionsValues.indexOf('c') > -1 || codeGroup.fitting_codes.value === 'cb2'))
        return true;
      return false;
    },
    execute: (data) => {
      const options = data[KEY_OPTIONS][0].map((option) => {
        if (option.value === 'f') {
          option.disabled = true;
        }
        return option;
      });

      return {
        ...data,
        options: [options],
      };
    },
  },
  
  {
    check: (product, codeGroup, optionsValues) => {
      if (
        optionsValues.indexOf('f') === -1
      ){
        return true;
      }
        
      return false;
    },
    execute: (data) => {
      if (data !== undefined) {
        if (data[KEY_OPTIONS] === undefined) return;
        const options = data[KEY_OPTIONS][0].map((option) => {
          if (option.value === 'c')
            option.disabled = false;
          return option;
        });

        return {
          ...data,
          options: [options],
        };
      }
    },
  },
  
  {
    check: (product, codeGroup, optionsValues) => {
      if (codeGroup[KEY_OPTIONS].length && optionsValues.indexOf('f') > -1){
        return true;
      }
      return false;
    },
    execute: (data) => {
      const options = data[KEY_OPTIONS][0].map((option) => {
        if (option.value === 'c') {
          option.disabled = true;
        }
        return option;
      });

      return {
        ...data,
        options: [options],
      };
    },
  },
  // h
  
  {
    check: (product, codeGroup, optionsValues) => {
      if (optionsValues.indexOf('f') === -1) return true;
      return false;
    },
    execute: (data) => {
      if (data !== undefined) {
        if (data[KEY_OPTIONS] === undefined) return;
        const options = data[KEY_OPTIONS][0].map((option) => {
          if (option.value === 'c') option.disabled = false;
          return option;
        });

        return {
          ...data,
          options: [options],
        };
      }
    },
  },
  */, // https://bravemarketing.atlassian.net/wiki/spaces/OR/pages/1530593281/Spec+Code+Functionality#%E2%80%9COptions%E2%80%9D-Field-Dependencies
  // https://bravemarketing.atlassian.net/wiki/spaces/OR/pages/1530593281/Spec+Code+Functionality#%E2%80%9COptions%E2%80%9D-Field-Dependencies
  /*
   // https://bravemarketing.atlassian.net/wiki/spaces/OR/pages/1530593281/Spec+Code+Functionality#%E2%80%9COptions%E2%80%9D-Field-Dependencies
  {
    check: (product, codeGroup, optionsValues) => {
      if (codeGroup[KEY_OPTIONS].length && optionsValues.indexOf('c') > -1)
        return true;
      return false;
    },
    execute: (data) => {
      const options = data[KEY_OPTIONS][0].map((option) => {
        option.disabled = option.value === 'f' ? true : option.disabled;
        return option;
      });

      return {
        ...data,
        options: [options],
      };
    },
  },
  {
    check: (product, codeGroup, optionsValues) => {
      if (codeGroup[KEY_OPTIONS].length && optionsValues.indexOf('f') > -1) {
        return true;
      }
      return false;
    },
    execute: (data) => {
      const options = data[KEY_OPTIONS][0].map((option) => {
        option.disabled = option.value === 'c' ? true : option.disabled;
        return option;
      });
      return {
        ...data,
        options: [options],
      };
    },
  },
  {
    check: (product, codeGroup, optionsValues) => {
      if (optionsValues.indexOf('c') === -1 && codeGroup.fitting_codes.value !== 'cb2') {
        return true;
      }
      return false;
    },
    execute: (data) => {
      if (data[KEY_OPTIONS]?.length) {
        const options = data[KEY_OPTIONS][0].map((option) => {
          option.disabled = option.value === 'f' ? false : option.disabled;
          return option;
        });
        return {
          ...data,
          options: [options],
        };
      }
    },
  },
  {
    check: (product, codeGroup, optionsValues) => {
      if (optionsValues.indexOf('f') === -1) {
        return true;
      }
      return false;
    },
    execute: (data) => {
      if (data) {
        const options = data[KEY_OPTIONS][0]?.map((option) => {
          option.disabled = option.value === 'c' ? false : option.disabled;
          return option;
        });
        return {
          ...data,
          options: [options],
        };
      }
    },
  }, 
 */
  /*
---------
  {
    check: (product, codeGroup, optionsValues) => {
      if (
        optionsValues.indexOf('c') === -1
      ){
        return true;
      }
        
      return false;
    },
    execute: (data) => {
      if (data !== undefined) {
        if (data[KEY_OPTIONS] === undefined) return;
        const options = data[KEY_OPTIONS][0].map((option) => {
          if (option.value === 'f')
            option.disabled = false;
          return option;
        });

        return {
          ...data,
          options: [options],
        };
      }
    },
  },
  */ {
    check: (product, codeGroup, optionsValues) => {
      if (optionsValues.indexOf('h') > -1 && product !== 'tiyo') return true;
      return false;
    },
    execute: (data) => {
      const options = data[KEY_OPTIONS][0].map((option) => {
        option.disabled =
          option.value === 'n' || option.value === '65'
            ? true
            : option.disabled;
        return option;
      });

      const engine_types = data[KEY_ENGINE_TYPES][0].map((engine) => {
        engine.disabled = engine.value === 'lo' ? true : false;
        return engine;
      });

      return {
        ...data,
        options: [options],
        engine_types: [engine_types],
      };
    },
  },
  // https://bravemarketing.atlassian.net/wiki/spaces/OR/pages/1530593281/Spec+Code+Functionality#%E2%80%9COptions%E2%80%9D-Field-Dependencies
  {
    check: (product, codeGroup, optionsValues) => {
      if (optionsValues.indexOf('n') === -1) return true;
      return false;
    },
    execute: (data) => {
      if (data !== undefined) {
        if (data[KEY_OPTIONS] === undefined) return;
        const options = data[KEY_OPTIONS][0].map((option) => {
          if (option.value === 'h') option.disabled = false;
          return option;
        });

        return {
          ...data,
          options: [options],
        };
      }
    },
  },
  {
    check: (product, codeGroup, optionsValues) => {
      if (optionsValues.indexOf('h') > -1) return true;
      return false;
    },
    execute: (data) => {
      const options = data[KEY_OPTIONS][0].map((option) => {
        option.disabled = option.value === 'n' ? true : option.disabled;
        return option;
      });

      return {
        ...data,
        options: [options],
      };
    },
  },
  {
    check: (product, codeGroup, optionsValues) => {
      if (optionsValues.indexOf('n') > -1) return true;
      return false;
    },
    execute: (data) => {
      const options = data[KEY_OPTIONS][0].map((option) => {
        option.disabled = option.value === 'h' ? true : option.disabled;
        return option;
      });

      return {
        ...data,
        options: [options],
      };
    },
  },
  /*
  {
    check: (product, codeGroup, optionsValues) => {
      if (optionsValues.indexOf('f') === -1) return true;
      return false;
    },
    execute: (data) => {
      if (data !== undefined) {
        if (data[KEY_OPTIONS] === undefined) return;
        const options = data[KEY_OPTIONS][0].map((option) => {
          if (option.value === 'c') option.disabled = false;
          return option;
        });

        return {
          ...data,
          options: [options],
        };
      }
    },
  },
  */
  {
    check: (product, codeGroup, optionsValues) => {
      if (optionsValues.indexOf('c') > -1) return true;
      return false;
    },
    execute: (data) => {
      const options = data[KEY_OPTIONS][0].map((option) => {
        option.disabled = option.value === 'f' ? true : option.disabled;
        return option;
      });

      return {
        ...data,
        options: [options],
      };
    },
  },
  {
    check: (product, codeGroup, optionsValues) => {
      if (optionsValues.indexOf('f') > -1) return true;
      return false;
    },
    execute: (data) => {
      const options = data[KEY_OPTIONS][0].map((option) => {
        option.disabled = option.value === 'c' ? true : option.disabled;
        return option;
      });

      return {
        ...data,
        options: [options],
      };
    },
  },

  // https://bravemarketing.atlassian.net/wiki/spaces/OR/pages/1530593281/Spec+Code+Functionality#%E2%80%9COptions%E2%80%9D-Field-Dependencies
  /*{
    check: (product, codeGroup, optionsValues) => {
      if (
        optionsValues.indexOf('54') === -1 &&
        //product !== 'fina' &&
        product !== 'riva' &&
        product !== 'vira'
      )
        return true;
      return false;
    },
    execute: (data) => {
      if (data !== undefined) {
        if (data[KEY_OPTIONS] === undefined) return;
        const options = data[KEY_OPTIONS][0].map((option) => {
          if (option.value === '65' || option.value === 'p')
            option.disabled = false;
          return option;
        });

        return {
          ...data,
          options: [options],
        };
      }
    },
  },
  */
  {
    check: (product, codeGroup, optionsValues) => {
      if (codeGroup[KEY_OPTIONS].length && optionsValues.indexOf('54') > -1)
        return true;
      return false;
    },
    execute: (data) => {
      const options = data[KEY_OPTIONS][0].map((option) => {
        if (option.value === '65' || option.value === 'p') {
          option.disabled = true;
        }
        return option;
      });

      return {
        ...data,
        options: [options],
      };
    },
  },
  // https://bravemarketing.atlassian.net/wiki/spaces/OR/pages/1530593281/Spec+Code+Functionality#%E2%80%9COptions%E2%80%9D-Field-Dependencies
  {
    check: (product, codeGroup, optionsValues) => {
      if (
        optionsValues.indexOf('65') === -1 ||
        optionsValues.indexOf('p') === -1
      )
        return true;
      return false;
    },
    execute: (data) => {
      if (data === undefined) return;

      const options = data[KEY_OPTIONS][0].map((option) => {
        if (option.value === '54') option.disabled = false;
        return option;
      });

      return {
        ...data,
        options: [options],
      };
    },
  },
  {
    check: (product, codeGroup, optionsValues) => {
      if (optionsValues.indexOf('65') > -1 || optionsValues.indexOf('p') > -1)
        return true;
      return false;
    },
    execute: (data) => {
      const options = data[KEY_OPTIONS][0].map((option) => {
        option.disabled = option.value === '54' ? true : option.disabled;
        return option;
      });

      return {
        ...data,
        options: [options],
      };
    },
  },
  {
    check: (product, codeGroup, optionsValues) => {
      if (
        (optionsValues.indexOf('65') > -1 ||
          optionsValues.indexOf('67') > -1) &&
        (codeGroup[KEY_BEZEL_COLOURS].value === 'g' ||
          codeGroup[KEY_BEZEL_COLOURS].value === 'k' ||
          codeGroup[KEY_BEZEL_COLOURS].value === 'l' ||
          codeGroup[KEY_BEZEL_COLOURS].value === 'z')
      )
        return true;
      return false;
    },
    warning: () => {
      return 'alert-plated';
    },
    execute: (data) => {
      /*
      const options = data[KEY_OPTIONS][0].map((option) => {
        option.disabled = option.value === '54' ? true : option.disabled;
        return option;
      });
*/
      return {
        ...data,
      };
    },
  },
  {
    check: (product, codeGroup, optionsValues) => {
      if (
        (product === 'toto' ||
          product === 'toto-mini' ||
          product === 'toto-micro' ||
          product === 'toto-plus' ||
          product === 'toto-zero' ||
          product === 'alta' ||
          product === 'alta-mini' ||
          product === 'alta-micro' ||
          product === 'alta-plus' ||
          product === 'alta-zero' ||
          product === 'vio-tall' ||
          product === 'vio-square' ||
          product === 'vio-tall') &&
        (optionsValues.indexOf('65') > -1 ||
          optionsValues.indexOf('67') > -1) &&
        (codeGroup[KEY_BEZEL_COLOURS].value === 'w' ||
          codeGroup[KEY_BEZEL_COLOURS].value === 'd' ||
          codeGroup[KEY_BEZEL_COLOURS].value === 'b' ||
          codeGroup[KEY_BEZEL_COLOURS].value === 'c' ||
          codeGroup[KEY_BEZEL_COLOURS].value === 'g' ||
          codeGroup[KEY_BEZEL_COLOURS].value === 'k' ||
          codeGroup[KEY_BEZEL_COLOURS].value === 'l' ||
          codeGroup[KEY_BEZEL_COLOURS].value === 'z')
      )
        return true;
      return false;
    },
    warning: () => {
      return 'alert-modifiedplated';
    },
    execute: (data) => {
      /*
      const options = data[KEY_OPTIONS][0].map((option) => {
        option.disabled = option.value === '54' ? true : option.disabled;
        return option;
      });
*/
      return {
        ...data,
      };
    },
  },
  {
    check: (product, codeGroup, optionsValues) => {
      if (
        (optionsValues.indexOf('65') > -1 ||
          optionsValues.indexOf('67') > -1) &&
        (codeGroup[KEY_BEZEL_COLOURS].value === 'v' ||
          codeGroup[KEY_BEZEL_COLOURS].value === 't' ||
          codeGroup[KEY_BEZEL_COLOURS].value === 'r' ||
          codeGroup[KEY_BEZEL_COLOURS].value === 'y')
      )
        return true;
      return false;
    },
    warning: () => {
      return 'alert-premium';
    },
    execute: (data) => {
      return {
        ...data,
      };
    },
  },
  {
    check: (product, codeGroup, optionsValues) => {
      if (
        codeGroup[KEY_BEZEL_COLOURS].value === 'v' ||
        codeGroup[KEY_BEZEL_COLOURS].value === 't' ||
        codeGroup[KEY_BEZEL_COLOURS].value === 'r' ||
        codeGroup[KEY_BEZEL_COLOURS].value === 'y'
      )
        return true;
      return false;
    },
    execute: (data) => {
      const options = data[KEY_OPTIONS][0].map((option) => {
        option.disabled = option.value === 'f' ? true : option.disabled;
        return option;
      });
      return {
        ...data,
        options: [options],
      };
    },
  },
  // https://bravemarketing.atlassian.net/wiki/spaces/OR/pages/1530593281/Spec+Code+Functionality#Product-specific-Field-Dependencies
  {
    check: (product, codeGroup, optionsValues) => {
      if (product === 'fina' && optionsValues.indexOf('h') > -1) return true;
      if (product === 'fina' && optionsValues.indexOf('n') > -1) return true;
      if (product === 'fina' && codeGroup[KEY_ENGINE_TYPES].value === 'lo')
        return true;
      return false;
    },
    execute: (data) => {
      const baffle_colours = data[KEY_BAFFLE_COLOURS][0].map((baffle) => {
        baffle.disabled = baffle.value === 'na' ? false : true;
        return baffle;
      });

      return {
        ...data,
        baffle_colours: [baffle_colours],
      };
    },
  },
  // https://bravemarketing.atlassian.net/wiki/spaces/OR/pages/1530593281/Spec+Code+Functionality#Product-specific-Field-Dependencies
  {
    check: (product, codeGroup) => {
      if (product === 'fina' && codeGroup[KEY_BAFFLE_COLOURS].value === 'na')
        return true;
      return false;
    },
    execute: (data) => {
      const options = data[KEY_OPTIONS][0].map((option) => {
        option.disabled =
          option.value === 'h' || option.value === 'n' ? true : false;
        return option;
      });
      /*
      const engine_types = data[KEY_ENGINE_TYPES][0].map((engine) => {
        engine.disabled = engine.value === 'lo' ? true : false;
        return engine;
      }); */

      return {
        ...data,
        options: [options],
        //engine_types: [engine_types],
      };
    },
  },
  // https://bravemarketing.atlassian.net/wiki/spaces/OR/pages/1530593281/Spec+Code+Functionality#Air-Range-Field-Dependencies
  {
    check: (product, codeGroup, optionsValues) => {
      if (
        (product === 'air-pendant' ||
          product === 'air-butterfly' ||
          product === 'air-screw' ||
          product === 'air-fixed' ||
          product === 'air-track' ||
          product === 'air-integrated' ||
          product === 'air-rose') &&
        optionsValues.indexOf('h') > -1
      )
        return true;
      return false;
    },
    execute: (data) => {
      const beam_angles = data[KEY_BEAM_ANGLES][0].map((beam) => {
        beam.disabled =
          beam.value === 'u' ||
          beam.value === 's' ||
          beam.value === 'n' ||
          beam.value === 'l' ||
          beam.value === 'o'
            ? true
            : false;
        return beam;
      });

      return {
        ...data,
        beam_angles: [beam_angles],
      };
    },
  },
  // https://bravemarketing.atlassian.net/wiki/spaces/OR/pages/1530593281/Spec+Code+Functionality#Air-Range-Field-Dependencies
  {
    check: (product, { beam_angles }) => {
      if (
        (product === 'air-pendant' ||
          product === 'air-butterfly' ||
          product === 'air-screw' ||
          product === 'air-fixed' ||
          product === 'air-track' ||
          product === 'air-integrated' ||
          product === 'air-rose') &&
        (beam_angles.value === 'u' ||
          beam_angles.value === 's' ||
          beam_angles.value === 'n' ||
          beam_angles.value === 'l' ||
          beam_angles.value === 'o')
      )
        return true;
      return false;
    },
    execute: (data) => {
      const options = data[KEY_OPTIONS][0].map((option) => {
        option.disabled = option.value === 'h' ? true : option.disabled;
        return option;
      });

      return {
        ...data,
        options: [options],
      };
    },
  },
  // https://bravemarketing.atlassian.net/wiki/spaces/OR/pages/1530593281/Spec+Code+Functionality#%E2%80%9COptions%E2%80%9D-Field-Dependencies
  /*
  {
    check: (product, codeGroup, optionsValues) => {
      if (optionsValues.indexOf('h') === -1) return true;
      return false;
    },
    execute: (data) => {
      if (data !== undefined) {
        if (data[KEY_OPTIONS] === undefined) return;
        const options = data[KEY_OPTIONS][0].map((option) => {
          if (option.value === 'n') option.disabled = false;
          return option;
        });

        return {
          ...data,
          options: [options],
        };
      }
    },
  },
  */
  {
    check: (product, codeGroup, optionsValues) => {
      if (optionsValues.indexOf('n') === -1) return true;
      return false;
    },
    execute: (data) => {
      if (data !== undefined) {
        if (data[KEY_OPTIONS] === undefined) return;
        const options = data[KEY_OPTIONS][0].map((option) => {
          if (option.value === 'h') option.disabled = false;
          return option;
        });

        return {
          ...data,
          options: [options],
        };
      }
    },
  },
  {
    check: (product, codeGroup, optionsValues) => {
      if (
        codeGroup[KEY_ENGINE_TYPES].value === 'lo' &&
        optionsValues.indexOf('65') > -1
      )
        return true;
      return false;
    },
    execute: (data) => {
      const options = data[KEY_OPTIONS][0].map((option) => {
        option.disabled = option.value === 'h' ? true : option.disabled;
        return option;
      });

      return {
        ...data,
        options: [options],
      };
    },
  },
  // https://bravemarketing.atlassian.net/wiki/spaces/OR/pages/2329804809/1.2.0+-+Spec+Code+Functionality#Roc-%26-Cue
  {
    check: (product, codeGroup, optionsValues) => {
      if (
        (product === 'cue-100' ||
          product === 'cue-100-lopro' ||
          product === 'roc-100' ||
          product === 'roc-100-lopro') &&
        optionsValues.indexOf('67') === -1
      )
        return true;
      return false;
    },
    execute: (data) => {
      if (data === undefined) return;
      const bezel_colours = data[KEY_BEZEL_COLOURS][0].map((bezel) => {
        if (
          bezel.value === 'w' ||
          bezel.value === 'd' ||
          bezel.value === 'e' ||
          bezel.value === 'b' ||
          bezel.value === 'g' ||
          bezel.value === 'j' ||
          bezel.value === 'k' ||
          bezel.value === 'l' ||
          bezel.value === 'z' ||
          bezel.value === 'c'
        )
          bezel.disabled = false;
        return bezel;
      });

      return {
        ...data,
        bezel_colours: [bezel_colours],
      };
    },
  },
  {
    check: (product, codeGroup, optionsValues) => {
      if (
        (product === 'cue-100' ||
          product === 'cue-100-lopro' ||
          product === 'roc-100' ||
          product === 'roc-100-lopro') &&
        optionsValues.indexOf('67') > -1
      )
        return true;
      return false;
    },
    execute: (data) => {
      const bezel_colours = data[KEY_BEZEL_COLOURS][0].map((bezel) => {
        bezel.disabled =
          bezel.value === 'w' ||
          bezel.value === 'd' ||
          bezel.value === 'e' ||
          bezel.value === 'b' ||
          bezel.value === 'g' ||
          bezel.value === 'j' ||
          bezel.value === 'k' ||
          bezel.value === 'l' ||
          bezel.value === 'z' ||
          bezel.value === 'c'
            ? true
            : false;
        return bezel;
      });

      return {
        ...data,
        bezel_colours: [bezel_colours],
      };
    },
  },
  {
    check: (product, codeGroup, optionsValues) => {
      if (
        (product === 'toto-zero' ||
          product === 'toto-micro' ||
          product === 'toto-mini' ||
          product === 'toto-plus' ||
          product === 'toto' ||
          product === 'alta-zero' ||
          product === 'alta-micro' ||
          product === 'alta-mini' ||
          product === 'alta' ||
          product === 'alta-plus') &&
        optionsValues.indexOf('67') > -1
      )
        return true;
      return false;
    },
    execute: (data) => {
      if (data !== undefined) {
        if (data[KEY_OPTIONS] === undefined) return;
        const options = data[KEY_OPTIONS][0].map((option) => {
          if (option.value === '54') option.disabled = true;
          return option;
        });

        return {
          ...data,
          options: [options],
        };
      }
    },
  },
];

export default STATEMENTS;
