import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { submitGetInTouchReset } from 'redux/actions';
import { GetInTouchForm } from 'components/forms/get-in-touch-form';
import { Button } from 'components/buttons';
import { SignUpTitle } from 'components/sign-up-title';
import { Loading } from 'components/loading';
import { SIZE_BIG } from 'constants/sizes';
import { THEME_DARK } from 'constants/color-theme';
import { LABEL_CALL_US } from 'constants/labels';
import styles from './style.module.scss';

export const GetInTouch = ({ image, formContext }) => {
  const dispatch = useDispatch();
  const { loading, success, hasError } = useSelector(
    (state) => state.getInTouchForm
  );

  useEffect(() => {
    if (success) {
      const reset = () => dispatch(submitGetInTouchReset());
      setTimeout(reset, 5000);
    }
  }, [success, dispatch]);

  return (
    <section className={styles['get-in-touch']}>
      <div
        className={styles.info}
        style={{
          backgroundImage: `url(${image})`,
        }}
      >
        <div>
          <h2>get in touch</h2>
          <span>00 44 (0) 1923 889712</span>
          <a href="mailto:info@orluna.com">info@orluna.com</a>
          <address>
            Orluna LED Technologies Limited
            <br />
            Unit A, Chiltern Park Industrial Estate,
            <br />
            Dunstable, LU5 4LT
            <br />
            United Kingdom
          </address>
          <a href="tel:+44 (0) 1923 889712" className={styles.mobile}>
            <Button size={SIZE_BIG} theme={THEME_DARK} label={LABEL_CALL_US} />
          </a>
        </div>
      </div>
      <div className={styles.form}>
        <div className={styles.title}>
          <SignUpTitle title="book a consultation" entered={true} />
        </div>
        {!success && <GetInTouchForm formContext={formContext} />}
        {loading && <Loading overlay fit />}
        {success && (
          <div className={styles.confirmation}>
            <p>
              Thanks for requesting a call back consultation.
              <br />
              We'll be in touch as soon as we can. Our office hours are Monday
              to Friday, 9am until 5pm GMT.
            </p>
          </div>
        )}
        {hasError && (
          <div className={styles.error}>
            <p>
              Sorry there was an error submitting the form. Please try again
              later.
            </p>
          </div>
        )}
      </div>
    </section>
  );
};
