import React from 'react';
import { Link } from 'react-router-dom';
import { ROUTE_CONTACT } from 'constants/router-links';
import { LogoIcon } from 'components/logos';
import { Arrow } from 'components/icons';
import styles from './style.module.scss';

export const ContactDetails = () => {
  return (
    <div className={styles['contact-details']}>
      <LogoIcon className={styles['logo-icon']} />
      <h3>UK Headquarters</h3>
      <address>
        Orluna LED Technologies Limited
        <br />
        Unit A, Chiltern Park Industrial Estate,
        <br />
        Dunstable, LU5 4LT <br />
        United Kingdom <br />
        <a href="mailto:info@orluna.com">info@orluna.com</a>
        <br />
        <a className={styles['phone-mobile']} href="tel:+44 (0) 1923 889712">
          00 44 (0) 1923 889712
        </a>
        <span className={styles['phone-desktop']}>00 44 (0) 1923 889712</span>
      </address>
      <Link to={ROUTE_CONTACT} className={styles['sales-link']}>
        international sales
        <Arrow />
      </Link>
    </div>
  );
};
