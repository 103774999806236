import React from 'react';
import { SIZE_BIG } from 'constants/sizes';
import { THEME_DARK } from 'constants/color-theme';
import { LABEL_DOWNLOAD } from 'constants/labels';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { FloatingLogo } from 'components/floating-logo';
import { Button } from 'components/buttons';
import productDatasheetIcon from 'assets/images/product-datasheet.svg';
import installationInstructionIcon from 'assets/images/installation-instruction.svg';
import trackDatasheetIcon from 'assets/images/track-datasheet.svg';
import linedrawingIcon from 'assets/images/line_drawing.svg';
import styles from './style.module.scss';

export const LineDrawingAndDatasheet = ({
  lineDrawing,
  datasheet,
  datasheets,
}) => {
  const generateDownloadUrl = (type, datasheet = '') => {
    const uris = {
      datasheet: datasheet.url,
      lineDrawing: lineDrawing.line_drawing,
    };

    const query = ['download?url=', uris[type]].join('');
    return [process.env.REACT_APP_DOWNLOADS_URL, 'api', query].join('/');
  };

  const dataClasses = classNames({
    [`${styles.wrapper}`]: true,
    [`${styles.disabled}`]: !datasheet.url,
  });

  const lineClasses = classNames({
    [`${styles.wrapper}`]: true,
    [`${styles.disabled}`]: !lineDrawing.line_drawing,
  });

  const datasheetsJSX = datasheets.map((datasheet) => {
    if (datasheet.datasheet_type === 'prod') {
      return (
        <div className={dataClasses} key={datasheet.url}>
          <div className={styles.option}>
            <a href={datasheet.url} target="_blank" rel="noopener noreferrer">
              <img src={productDatasheetIcon} alt="datasheet icon" />
            </a>
            <div>
              <h3>product datasheet</h3>
              <p>
                A summary sheet of <br /> product specifications.
              </p>
              <a
                className={styles['preview-icon']}
                href={generateDownloadUrl('datasheet', datasheet)}
              >
                <Button theme={THEME_DARK} label={LABEL_DOWNLOAD} />
              </a>
            </div>
          </div>
          <a href={datasheet.url} target="_blank" rel="noopener noreferrer">
            view product datasheet
          </a>
        </div>
      );
    } else if (datasheet.datasheet_type === 'track') {
      return (
        <div className={dataClasses} key={datasheet.url}>
          <div className={styles.option}>
            <a href={0} target="_blank" rel="noopener noreferrer">
              <img src={trackDatasheetIcon} alt="datasheet icon" />
            </a>
            <div>
              <h3>track datasheet</h3>
              <p>
                A summary sheet of <br /> track specifications.
              </p>
              <a
                className={styles['preview-icon']}
                href={generateDownloadUrl('datasheet', datasheet)}
              >
                <Button theme={THEME_DARK} label={LABEL_DOWNLOAD} />
              </a>
            </div>
          </div>
          <a href={datasheet.url} target="_blank" rel="noopener noreferrer">
            view track datasheet
          </a>
        </div>
      );
    } else if (datasheet.datasheet_type === 'install') {
      return (
        <div className={dataClasses} key={datasheet.url}>
          <div className={styles.option}>
            <a href={datasheet.url} target="_blank" rel="noopener noreferrer">
              <img src={installationInstructionIcon} alt="datasheet icon" />
            </a>
            <div>
              <h3>installation instruction</h3>
              <p>
                An instructional sheet to <br /> assist product installation.
              </p>
              <a
                className={styles['preview-icon']}
                href={generateDownloadUrl('datasheet', datasheet)}
              >
                <Button theme={THEME_DARK} label={LABEL_DOWNLOAD} />
              </a>
            </div>
          </div>
          <a href={datasheet.url} target="_blank" rel="noopener noreferrer">
            view installation instruction
          </a>
        </div>
      );
    } else {
      return (
        <div className={dataClasses} key={datasheet.url}>
          <div className={styles.option}>
            <a href={datasheet.url} target="_blank" rel="noopener noreferrer">
              <img src={productDatasheetIcon} alt="datasheet icon" />
            </a>
            <div>
              <h3>product datasheet</h3>
              <p>
                A summary sheet of <br /> product specifications.
              </p>
              <a
                className={styles['preview-icon']}
                href={generateDownloadUrl('datasheet', datasheet)}
              >
                <Button theme={THEME_DARK} label={LABEL_DOWNLOAD} />
              </a>
            </div>
          </div>
          <a href={datasheet.url} target="_blank" rel="noopener noreferrer">
            view product datasheet
          </a>
        </div>
      );
    }
  });

  return (
    <section className={styles['line-drawings']}>
      <span>
        <FloatingLogo size={SIZE_BIG} />
      </span>
      <h2>view specifications</h2>
      <div className={styles['section-wrapper']}>
        {/*
        <div className={dataClasses}>
          <div className={styles.option}>
            <a href={datasheet.url} target="_blank" rel="noopener noreferrer">
              <img src={datasheetIcon} alt="datasheet icon" />
            </a>
            <div>
              <h3>datasheet</h3>
              <p>
                A summary sheet of <br /> product specifications.
              </p>
              <a
                className={styles['preview-icon']}
                href={generateDownloadUrl('datasheet', datasheet)}
              >
                <Button theme={THEME_DARK} label={LABEL_DOWNLOAD} />
              </a>
            </div>
          </div>
          <a href={datasheet.url} target="_blank" rel="noopener noreferrer">
            view datasheet
          </a>
        </div>
      */}
        {datasheetsJSX}
        <div className={lineClasses}>
          <div className={styles.option}>
            <a
              href={lineDrawing.line_drawing ? lineDrawing.line_drawing : ''}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={linedrawingIcon} alt="linedrawing icon" />
            </a>
            <div>
              <h3>line drawing</h3>
              <p>A technical drawing showing size and dimensions of product.</p>
              <a
                className={styles['preview-icon']}
                href={generateDownloadUrl('lineDrawing')}
              >
                <Button theme={THEME_DARK} label={LABEL_DOWNLOAD} />
              </a>
            </div>
          </div>
          <a
            href={lineDrawing.line_drawing ? lineDrawing.line_drawing : ''}
            target="_blank"
            rel="noopener noreferrer"
          >
            view line drawing
          </a>
        </div>
      </div>
    </section>
  );
};

LineDrawingAndDatasheet.propTypes = {
  lineDrawing: PropTypes.object,
  datasheet: PropTypes.object,
};
